import React from "react";
import {NavbarMain} from "./NavbarMain";
import {Cover} from "./Cover";
import {Services} from "./services";
import {Registry} from "./registry/Registry";
import {Queries} from "./queries";
import {Benefits} from "./Benefits";
import {HowWork} from "./HowWork";
import {Testimonials} from "./testimonials";
import {DownloadNow} from "./DownloadNow";
import {Contact} from "./Contact";
import {Footer} from "./Footer";

export const Main = () => {
    return (
        <>
            <NavbarMain/>
            <Cover/>
            <Services/>
            <Registry/>
            <Queries/>
            <Benefits/>
            <HowWork/>
            <Testimonials/>
            <DownloadNow/>
            <Contact/>
            <Footer/>
        </>
    );
 }
