import React from "react";
import Card from 'react-bootstrap/Card'

export const Querie_card=(props)=>{
    return(
        <>
            <Card className="text-center queries_card" style={{ width: '14rem', height:'10rem', borderRadius:"5%" }}>
                <Card.Body>
                    <Card.Img variant="top" src={props.image} style={{height:"70%",width:"70%"}} />
                    <Card.Title>{props.title}</Card.Title>
                </Card.Body>
            </Card>
        </>
    );
}
