import React from "react";
import Card from 'react-bootstrap/Card'

export const Benefit_card = (props) => {
    return (

        <Card className="text-center benefit" style={{
            width: '18rem',
            height: '20rem',
            borderRadius: "1.5rem",
            background: props.backGC,
            color: "#fff"
        }}>
            <Card.Body>
                <Card.Img className="pt-5" variant="top" src={props.image} style={{height: "40%", width: "40%"}}/>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
            </Card.Body>
        </Card>
    );
}
