import React from "react";
import {Col, Image, Row} from "react-bootstrap";

const icon_check = document.getElementById("inp-img-check-circle").value;

export const DotSpecialist = ({description}) => {
    return (
        <Row className="my-3" >
            <Col xs={3} lg={2} className="text-center">
                <Image src={icon_check} />
            </Col>
            <Col xs={9} lg={10} className="px-1">
                <p className="registry_dot_point">
                    {description}
                </p>
            </Col>
        </Row>
    )
}
