import React from "react";
import {Col, Image, Row} from "react-bootstrap";

const backgroundDownload = document.getElementById("inp-img-background-download").value;
const imagesAppMobile = document.getElementById("inp-img-group-images").value;

const googlePlay = document.getElementById('inp-img-google-play').value;
const appStore = document.getElementById('inp-img-app-store').value;

export const DownloadNow = () => {

    const handleClick = () => {
        window.open(window.location.origin+"/principal");
    }

    const handleClickDownload = (app) => {
        app ?
            window.open('https://play.google.com/store/apps/details?id=mx.com.satoritech.therappy')
            :
            window.open('https://apps.apple.com/mx/app/dr-loop/id1544641708')
        ;
    }

    return (
        <Row id="DowloadApp" className="justify-content-center align-items-center p-0 m-0 py-5" style={{
            backgroundImage: `url(${backgroundDownload})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            <Col xs={12} lg={{span: 5, offset: 1}} className="text-center">
                <Row>
                    <Col>
                        <Row>
                            <Col xs={{span: 10, offset: 1}} lg={{span: 12, offset: 0}}>
                                <b className="download_now">
                                    ¡Descárgala ahora!
                                </b>
                            </Col>
                            <Col xs={{span: 10, offset: 1}} lg={{span: 12, offset: 0}}>
                                <p className="download_available mt-4">
                                    Disponible para Android y iOS
                                </p>
                            </Col>
                            <Col xs={{span: 6, offset: 3}} lg={{span: 10, offset: 1}}>
                                <p className="download_signUp mt-3">
                                    Registrate en nuestra app y disfruta de todos los beneficios que Dr. Loop tiene para
                                    ti.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={{span: 6, offset: 3}} lg={{span: 6, offset: 3}}
                                 className="text-center mb-4 mb-lg-0">
                                <button onClick={handleClick} className="btn btn_downloadNow w-100">
                                    <span className="replies2">¡Registrarme ahora!</span>
                                    <span className="comment2">¡Registrarme!</span>
                                </button>
                            </Col>
                            {/*<Col xs={{span: 6, offset: 3}} lg={{span: 6, offset: 0}} className="text-center">*/}
                            {/*    <button onClick={() => handleClickDownload(true)} className="btn btn_downloadNow w-100">*/}
                            {/*        <span className="replies">Descargar App</span>*/}
                            {/*        <span className="comment">¡Registrarme!</span>*/}
                            {/*    </button>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row className="mt-5">
                            <Col xs={{span: 4, offset: 2}} className="text-end">
                                <Image onClick={() => handleClickDownload(true)} style={{cursor:"pointer"}} src={googlePlay} className="w-100"/>
                            </Col>
                            <Col xs={4} className="text-start">
                                <Image onClick={() => handleClickDownload(false)} style={{cursor:"pointer"}} src={appStore} className="w-100"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 1}}>
                <Image src={imagesAppMobile} className="w-100"/>
            </Col>
        </Row>
    )
}
