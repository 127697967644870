import React from "react";
const logoDrLoop = document.getElementById('inp-img-logoDrLoop').value;
const mail_icon = document.getElementById('mail_icon').value;
const whats_icon = document.getElementById('whats_icon').value;

export const Footer=()=>{
    return(
        <>
            <div className="footer" style={{backgroundColor:"#8A4AFF", color:"#fff", height:"100%", width:"100%"}}>
                <div className="row m-0 pt-3">
                <div className="col-lg-4 col-md-4 col-12">
                <div className="d-flex justify-content-center">
                    <img style={{width:"60%",height:"60%"}} src={logoDrLoop}/>
                </div>
                    <div className="d-flex justify-content-center px-lg-5 ml-lg-5 px-5 ml-5 px-md-3 ml-md-3">
                        <p style={{fontSize:"15px"}}>Salud en un click</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-6">
                    <h4>Navegación</h4>
                    <div className="row mt-2">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div><a className="footer__link" href="#aboutUs">Acerca de</a></div>
                        <div><a className="footer__link" href="#appointment">Consultas</a></div>
                        <div><a className="footer__link" href="#benefit">Beneficios</a></div>
                        <div><a className="footer__link" href="#HowWork">¿Como funciona?</a></div>
                    </div>
                    <div className="col-6 col-md-6 col-12">
                        <div><a className="footer__link" href="#testimonials">Testimoniales</a></div>
                        <div><a className="footer__link" href="#DowloadApp">Descarga de la app</a></div>
                        <div><a className="footer__link" href="/principal">Búsqueda de especialista</a></div>
                        <div><a className="footer__link" href="#contact">Contacto</a></div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-6">
                   <h4> Contacto </h4>
                    <div className="row mt-2">
                        <div className="col-lg-2 col-md-2 col-1">
                            <div >
                                <img className="icon_f" src={whats_icon}/>
                            </div>
                            <div >
                                <img className="icon_f" src={mail_icon}/>
                            </div>
                        </div>
                        <div className="overflow-hidden col-lg-8 col-md-8 col-12">
                            729 250 7231
                            <div className="scroll-text-right-left">
                                support@drloop.com.mx
                                servicioalcliente@drloop.com.mx
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    Dr. Loop © 2022 Todos los derechos reservados
                </div>
            </div>
        </>
    );
}
