import React from "react";
import {Col, Row, Image} from "react-bootstrap";
//  IMAGES
const cellHand = document.getElementById('inp-img-cellHand').value;
const messages = document.getElementById('inp-img-messages').value;
const docConsultation = document.getElementById('inp-img-docConsultation').value;
const medicines = document.getElementById('inp-img-medicines').value;
const imageApp = document.getElementById('inp-img-imageApp').value;

export const HowWork = () => {
    return (
        <div id="HowWork">
            <h2 className="text-center my-5" style={{color:"#8A4AFF"}}>
                <strong>
                    ¿Cómo funciona?
                </strong>
            </h2>
            <Row className="m-0">
                <Col xs={{span: 12}} lg={{span: 6}} className="h-400px">
                    <div className="d-flex align-items-center my-3">
                        <div className="background-light-blue ml-5 mr-3 p-3 rounded-circle">
                            <Image src={cellHand} style={{width: 'auto', height: '50px'}} alt="messages"/>
                        </div>
                        <div className="mr-5 mr-lg-0">
                            <span className="text-blue">Visita</span> nuestro sitio web o <span className="text-blue">descarga </span>
                            la aplicación para poder selecciona al médico que necesitas.
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <div className="background-purple ml-5 mr-3 p-3 rounded-circle">
                            <Image src={messages} style={{width: 'auto', height: '50px'}} alt="messages"/>
                        </div>
                        <div className="mr-5 mr-lg-0">
                            Busca al <span className="text-blue">especialista</span> según tus necesidades y solicita una cita.
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <div className="background-light-blue ml-5 mr-3 p-3 rounded-circle">
                            <Image src={docConsultation} style={{width: 'auto', height: '50px'}} alt="messages"/>
                        </div>
                        <div className="mr-5 mr-lg-0">
                            Una vez aceptada tu cita por el especialista, podrás pagar y recibir tu <span className="text-blue">consulta </span>
                            en la fecha y hora elegida.
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <div className="background-purple ml-5 mr-3 p-3 rounded-circle">
                            <Image src={medicines} style={{width: 'auto', height: '50px'}} alt="messages"/>
                        </div>
                        <div className="mr-5 mr-lg-0">
                            Al <span className="text-blue">concluir tu consulta</span>, recibirás las indicaciones y tratamientos a seguir.
                            <span> No olvides dejar tu reseña para que otros usuarios la vean.</span>
                        </div>
                    </div>
                </Col>
                <Col xs={{span: 4, offset:1}} className="h-400px d-none d-lg-flex align-items-center">
                    <Image src={imageApp} style={{maxWidth: '100%', maxHeight: '100%'}} alt="imageApp"/>
                </Col>
            </Row>
        </div>
    );
}
