import React from 'react';
import Card from 'react-bootstrap/Card'
const dialog = document.getElementById("service-at-home").value;
const screen = document.getElementById("service-online").value;
const doctor = document.getElementById("service-1").value;

export const Services = ()=>{
    return(
        <div>
            <h2 className="text-center p-5 my-5">
                <b style={{color:"#8A4AFF"}}>
                    ¡Dr. Loop</b> <b style={{color:"#1A0B55"}}>es una plataforma en la cuál los
               </b> <b style={{color:"#2EC3D4"}}> profesionales
            de la salud</b><b style={{color:"#1A0B55"}}> pueden ofrecer sus servicios!</b></h2>
            <div className="row m-0">

                <div className="col-12 col-lg-4 col-md-6 text-center d-flex justify-content-center mb-5">
            <Card className="services_Card services_shadow">
                <Card.Body>
                    <Card.Img variant="top" src={doctor} style={{height:"40%",width:"40%"}} />
                    <Card.Title style={{color:"#8A4AFF"}}>Presencial</Card.Title>
                    <Card.Text>
                        Solicita <strong> consultas en el consultorio</strong> del profesional
                        de la salud, más cercanos a tu ubicación
                    </Card.Text>
                </Card.Body>
            </Card>
                </div>

                <div className="col-12 col-lg-4 col-md-6 text-center d-flex justify-content-center mb-5">
            <Card className="services_shadow" style={{ width: '18rem',backgroundColor:"#8A4AFF",color:"#fff",height:'18rem',borderRadius:"5%"  }}>
                <Card.Body>
                    <Card.Img src={screen} style={{backgroundColor:"#Fff", borderRadius:"100%",height:"45%",width:"45%", padding:"2%"}} />

                        <Card.Title>Online</Card.Title>
                    <Card.Text>
                        Solicita consultas por videollamada y Chat
                    </Card.Text>
                </Card.Body>
            </Card>
                </div>

                <div className="col-12 col-lg-4 text-center d-flex justify-content-center mb-5">
            <Card className="services_Card services_shadow">
                <Card.Body>
                    <Card.Img src={dialog} style={{height:"40%",width:"40%",borderRadius:"5%"}} />
                    <Card.Title style={{color:"#8A4AFF"}}>A domicilio</Card.Title>
                    <Card.Text>
                        Solicita <strong> consultas en tu domicilio </strong>
                        con tu profesional de la salud de confianza
                    </Card.Text>
                </Card.Body>
            </Card>
                </div>
            </div>
        </div>
    );
}
