import React, {useRef} from "react";
import {Image, Navbar, Nav} from "react-bootstrap";
//  IMAGES
const backgroundPhoto = document.getElementById('inp-img-backgroundPhoto').value;
const logoDrLoop = document.getElementById('inp-img-logoDrLoop').value;
const whatsApp = document.getElementById('inp-img-whatsApp').value;



export const NavbarMain = () => {
    const handleFacebook = () => {
        window.open('https://www.facebook.com/Dr-Loop-100732385439698');
    }
    const handleInstagram = () => {
        window.open('https://www.instagram.com/dr_loop/');
    }
    const handleTwitter = () => {
        window.open('https://twitter.com/DrLoop_company?t=aw71cbZHAO7rFDgGEE_4pA&s=08');
    }
    const handleLinkedin = () => {
        window.open('https://www.linkedin.com/company/drloop');
    }
    const handleWhatsApp = () => {
        window.open('https://wa.me/7292507231');
    }
    return (
        <>
            <Navbar collapseOnSelect expand="lg" sticky="top" className="background-image">
                <Navbar.Brand className="px-4 py-3 text-background" href="#home">
                    <Image src={logoDrLoop} style={{width: 'auto', height: '50px'}} alt="Dr. Loop logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{zIndex: 1030, background: 'white'}} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link className="text-white px-xl-4 text-background" href="#aboutUs">Acerca de</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="#appointment">Consultas</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="#benefit">Beneficios</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="#HowWork">¿Cómo funciona?</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="#DowloadApp">Descarga nuestra app</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="#testimonials">Testimoniales</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="/principal">Búsqueda de especialista</Nav.Link>
                        <Nav.Link className="text-white px-xl-4 text-background" href="#contact">Contacto</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="fixed-center-left background-white border-radius-right">
                <div onClick={handleFacebook} className="d-flex justify-content-center align-items-center border-gray border-radius-top-right cursor-pointer size-40px hover-dark-blue">
                    <div className="icon-facebook size-40px "/>
                </div>
                <div onClick={handleInstagram} className="d-flex justify-content-center align-items-center border-gray cursor-pointer  size-40px hover-pink">
                    <div className="icon-instagram size-40px"/>
                </div>
                <div onClick={handleTwitter} className="d-flex justify-content-center align-items-center border-gray cursor-pointer  size-40px hover-light-blue">
                    <div className="icon-twitter size-40px"/>
                </div>
                <div onClick={handleLinkedin} className="d-flex justify-content-center align-items-center border-gray border-radius-bottom-right cursor-pointer size-40px hover-blue">
                    <div className="icon-linkedin size-40px"/>
                </div>
            </div>
            <div onClick={handleWhatsApp} className="background-green fixed-bottom-right rounded-circle cursor-pointer">
                <Image className="m-3" src={whatsApp} style={{width: 30, maxHeight: 30}} alt="WhatsApp"/>
            </div>
        </>
    );
}
