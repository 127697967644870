import React from 'react';
import {Main} from "./Main";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export const App = () => {
    return (
        <Main />
    );
}

if (document.getElementById('app')){
    ReactDOM.render(<App />, document.getElementById('app'));
}

