import React from "react";
const testimonial1 = document.getElementById("testimonial_1").value;
const testimonial2 = document.getElementById("testimonial_2").value;
const testimonial3 = document.getElementById("testimonial_3").value;
const comillas = document.getElementById("comillas").value;
const comillasC = document.getElementById("comillasC").value;
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card'


export const Testimonials=()=>{
    return(
        <div className="d-flex justify-content-center">
        <Card className="testimonial_shadow" style={{ width: '90%', height:'100%' }}>
        <div id="testimonials" className="mb-5 pt-5">
            <h1 className="text-center my-4" style={{color:"#8A4AFF"}}>
                <strong>
                    Testimoniales
                </strong>
            </h1>
            <Carousel>
                <Carousel.Item>
                    <div className="row m-0 pb-5">
                    <div className="col-lg-3 col-12 pl-5 d-flex justify-content-center">
                        <img style={{width: '10rem', height:'10rem', borderRadius:"100%"}} src={testimonial2} alt="face"/>
                    </div>
                        <div className="col-1">
                            <img src={comillas} alt="face"/>
                        </div>
                    <div className="col-lg-5 col-9">
                            <p className="pt-2" style={{color:"#443874",fontSize:20}}>
                                Como psicoterapeuta, me parece muy buena la
                                plataforma, la orientación al paciente es muy adecuada
                            </p>
                    </div>
                        <div className="col-1">
                            <img className="pt-5" src={comillasC} alt="face"/>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="row m-0 pb-5">
                    <div className="col-lg-3 col-12 pl-5 d-flex justify-content-center">
                        <img style={{width: '10rem', height:'10rem', borderRadius:"100%"}} src={testimonial3} alt="face"/>
                    </div>
                        <div className="col-1">
                            <img src={comillas} alt="face"/>
                        </div>
                    <div className="col-lg-5 col-9">
                            <p className="pt-2" style={{color:"#443874",fontSize:20}}>
                                He usado esta plataforma con dos profesionales de la salud
                                y me han sacado de varios apuros
                            </p>
                    </div>
                        <div className="col-1">
                            <img className="pt-5" src={comillasC} alt="face"/>
                          </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
        </Card>
        </div>
    );
}
