import React from "react";
import {Button, Col, Image, Row} from "react-bootstrap";

const coverPhoto = document.getElementById("inp-img-fondo-morado").value;
const phone = document.getElementById("inp-img-celular").value;

export const Cover = () => {
    return (
        <Row id="aboutUs" className="m-0 p-0 pt-5 cover"
             style={{
                 backgroundImage: `url(${coverPhoto})`,
             }}>
            <Col xs={{span:10, offset:1}} lg={{span:6, offset:1}} className="mt-0 pt-0 mt-lg-3 pt-lg-3 mt-xl-4 pt-xl-4">
                <Row>
                    <Col xs={12}>
                        <b className="cover_text">
                            En
                        </b>
                        <b className="cover_text cover_Blue">
                            {' '}Dr. Loop{' '}
                        </b>
                        <b className="cover_text">
                            te ayudamos a encontrar servicios de salud en línea y presencial.
                            De manera rápida, confiable y de acuerdo a tus necesidades
                        </b>
                    </Col>
                    <Col xs={10} className="mt-3">
                        <b className="cover_text_m">
                            Busca a tu especialista ideal por prestigio, cercania o precio
                        </b>
                    </Col>
                    <Col xs={{span: 8, offset: 1}} className="mt-1 pt-1 mt-lg-2 pt-lg-2 mt-xl-4 pt-xl-4">
                        <Button className="cover_button ms-5" size="lg" href="#contact">
                            <b className="p-3">
                                ¡Contáctanos!
                            </b>
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={{span:6, offset:4}} lg={{span:5, offset:0}} className="text-center px-0 mt-3 pt-3 mt-xl-4 pt-xl-4">
                <Image src={phone} className="w-100"/>
            </Col>
        </Row>
    );
}
