import React from "react";
import {Button, Card, Col, Image, Row} from "react-bootstrap";
import {DotSpecialist} from "./DotSpecialist";

const groupDoctors = document.getElementById('inp-img-doctors').value;
const googlePlay = document.getElementById('inp-img-google-play').value;
const appStore = document.getElementById('inp-img-app-store').value;

const points = [
    {id: "1", description: "Te ofrecemos la mejor herramienta digital para gestionar tus citas y consultas"},
    {id: "2", description: "Gestiona tus citas a través de nuestra app desde cualquier dispositivo Android o iOS"},
    {id: "3", description: "¿Planificar tu cita médica? Te ofrecemos la mejor solución"},
    {id: "4", description: "Tenemos el mejor buscador de especialistas de la salud"},
]

export const Registry = () => {

    const handleClick = () => {
        window.open(window.location.origin+"/principal");
    }

    const handleClickDownload = ( app ) => {
        app ?
            window.open('https://play.google.com/store/apps/details?id=mx.com.satoritech.therappy')
            :
            window.open('https://apps.apple.com/mx/app/dr-loop/id1544641708')
        ;
    }

    return (
        <Card className="mx-0 my-5 p-5 registry_shadow">
            <Row className="align-items-center">
                <Col xs={{span: 8, offset: 2}} lg={{span: 6, offset: 0}} className="text-center">
                    <Image src={groupDoctors} responsive="true" className="w-100"/>
                </Col>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={10}>
                            <b className="registry_title">
                                ¿Eres un especialista de la salud?
                            </b>
                        </Col>
                    </Row>
                    <div className="my-5">
                        {points.map(({id, description}) => {
                            return (
                                <DotSpecialist key={id} description={description}/>
                            )
                        })}
                    </div>
                    <Row className="align-items-center">
                        <Col xs={{span: 8, offset: 2}} lg={{span: 6, offset: 0}} className="text-center mb-3 mb-lg-0">
                            <button onClick={handleClick} className="btn-lg btn_registry_here px-3 pb-2 w-100">
                                ¡Regístrate aquí!
                            </button>
                        </Col>
                        <Col xs={{span: 4, offset: 2}} lg={{span: 3, offset: 0}}>
                            <Image onClick={() => handleClickDownload(true)}  style={{cursor:"pointer"}} src={googlePlay} className="w-100"/>
                        </Col>
                        <Col xs={4} lg={3}>
                            <Image onClick={() => handleClickDownload(false)}  style={{cursor:"pointer"}} src={appStore} className="w-100"/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}
