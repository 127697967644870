import React from "react";
import {Benefit_card} from "./Benefit_card";
const yellowImage = document.getElementById("benefit_1").value;
const directImage = document.getElementById("benefit_2").value;
const pinkImage = document.getElementById("benefit_3").value;
const atentionImage = document.getElementById("benefit_4").value;
const purpleImage = document.getElementById("benefit_5").value;
const lastImage = document.getElementById("benefit_6").value;


const cards=[
    {
        id:1,
        image:yellowImage,
        title:"Consulta rápida y segura",
        text:"Servicios de primera calidad proporcionados por profesionales que te ayudaran a mejorar tu salud",
        backGC:"#EFB274",
    },
    {
        id:2,
        image:directImage,
        title:"Directorio Médico",
        text:"Contamos con una amplia variedad de profesionales de la salud que te ayudaran en lo que tú necesites",
        backGC:"linear-gradient(#3FE5E0,#2395B8)",
    },
    {
        id:3,
        image:pinkImage,
        title:"Expediente clínico",
        text:"Al ingresar a la app, podrás generar tu expediente clínico. El cuál podrás compartir con tu profesional de la salud",
        backGC:"#9F2FFF",
    },
    {
        id:4,
        image:atentionImage,
        title:"Atención personalizada",
        text:"Platica con nuestros expertos, ellos te brindarán información puntual, de manera empática y con un excelente servicio",
        backGC:"#4386D2",
    },
    {
        id:5,
        image:purpleImage,
        title:"Consultas remotas o presenciales",
        text:"Contamos con modalidades presenciales (en consultorio y a domicilio), remotas (videollamada, llamada y chat).",
        backGC:"linear-gradient(#9F9CF4,#5D56D9)",
    },
    {
        id:6,
        image:lastImage,
        title:"App de fácil acceso",
        text:"Descarga la aplicación desde tu play store y solicita la consulta al instante.",
        backGC:"#2E8EDB",
    },
]

export const Benefits=()=>{
    return(
        <div id="benefit" style={{marginTop:"60px"}}>
            <h2 className="text-center" style={{color:"#8A4AFF"}}>
                <strong>
                    ¡Conoce nuestros beneficios!
                </strong>
            </h2>
            <div className="row justify-content-center m-0">
                {cards.map(item=>
                    <div key={item.id} className="col-lg-3 col-md-5 col-12 d-flex justify-content-center mx-1 my-5">
                        <Benefit_card
                            image={item.image}
                            title={item.title}
                            text={item.text}
                            backGC={item.backGC}
                        />
                    </div>)}
            </div>
        </div>
    );
}
