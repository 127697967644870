import React from "react";
const psicologia = document.getElementById("psychology").value;
const general = document.getElementById("general").value;
const nutriologo = document.getElementById("nutritionist").value;
const dermatologo = document.getElementById("dermatologist").value;
const podologo = document.getElementById("chiropodist").value;
const ginecologo = document.getElementById("gynecologist").value;
const veterinaria = document.getElementById("veterinarian").value;
const fisioterapia = document.getElementById("physiotherapy").value;
const neurologo = document.getElementById("neurologist").value;
const pediatra = document.getElementById("pediatrician").value;

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import Card from 'react-bootstrap/Card'
const cards=[
    {
        id:1,
        image:psicologia,
        title:"PSICOLOGÍA"
    },
    {
        id:2,
        image:general,
        title:"MÉDICO GENERAL"
    },
    {
        id:3,
        image:nutriologo,
        title:"NUTRIÓLOGO"
    },
    {
        id:4,
        image:dermatologo,
        title:"DERMATÓLOGO"
    },
    {
        id:5,
        image:podologo,
        title:"PODÓLOGO"
    },
    ]
const cards2=[
    {
        id:6,
        image:ginecologo,
        title:"GINECÓLOGO"
    },
    {
        id:7,
        image:veterinaria,
        title:"VETERINARIO"
    },
    {
        id:8,
        image:fisioterapia,
        title:"FISIOTERAPIA"
    },
    {
        id:9,
        image:neurologo,
        title:"NEUROLOGO"
    },
    {
        id:10,
        image:pediatra,
        title:"PEDIATRA"
    },
];
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    desktopmedium: {
        breakpoint: { max: 1024, min: 765 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 765, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
import {Querie_card} from "./querie_card";
export const Queries=()=>{
    return(
        <>
            <div id="appointment" className="text-center">
                <h2 style={{color:"#8A4AFF"}}><strong>Nuestras Consultas</strong></h2>
                <h6 style={{color:"#24165C"}}>Atención especializada y personalizada</h6>
            </div>
            <div className="my-5 mx-5">
            <Carousel
                ssr
                partialVisbile
                arrows
                responsive={responsive}
                infinite={false}
                autoPlay={false}
                shouldResetAutoplay={false}
            >
                {cards.map(item=>
                    <div key={item.id} className="d-flex justify-content-center">
                        <Querie_card
                            image={item.image}
                            title={item.title}
                        />
                    </div>
                )}
            </Carousel>
            </div>
            <div className="mx-5">
            <Carousel responsive={responsive}
                      infinite={false}
                      autoPlay={false}
                      arrows={true}
                      shouldResetAutoplay={false}
            >
                {cards2.map(item=>
                    <div key={item.id} className="d-flex justify-content-center">
                        <Querie_card
                            image={item.image}
                            title={item.title}
                        />
                    </div>
                )}
            </Carousel>
            </div>
        </>
    );
}
