import React, {useState} from "react";
import axios from 'axios';
import {Row, Col, Image} from "react-bootstrap";
import Swal from 'sweetalert2';

//  IMAGES
const contact = document.getElementById('inp-img-contact').value;
// URL
const url_contact = document.getElementById('inp-url-contact').value;

export const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const handleName=(e)=> {
        let value=e.target.value;
        setName(value)
    }

    const handleEmail=(e)=> {
        let value=e.target.value;
        setEmail(value)
    }

    const handleSubject=(e)=> {
        let value=e.target.value;
        setSubject(value)
    }

    const handleMessage=(e)=> {
        let value=e.target.value;
        setMessage(value)
    }

    const handleLoading=()=> {
        setLoading(!loading)
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        handleLoading();
        let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let email_val = regex.test(email);
        if (name !== '' && email !== '' && subject !== '' && message !== ''){
            if (email_val) {
                let formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('subject', subject);
                formData.append('message', message);
                axios.post(url_contact, formData).then( response => {
                    if (response.data.success){
                        Swal.fire(
                            '¡Mensaje enviado!',
                            'Su mensaje a sido enviado a nuestro servicio al cliente.',
                            'success'
                        ).then((result) => {
                            setLoading(false);
                            setName(()=>'');
                            setSubject('');
                            setEmail('');
                            setMessage('');
                        })
                    }else{
                        Swal.fire({
                            title:'¡Error!',
                            text:'Ocurrió un error inténtelo más tarde.',
                            icon:'error',
                            confirmButtonText: 'Aceptar',
                        }).then((result) => {
                            setLoading(false);
                        })
                    }
                });
            }
            else{
                Swal.fire(
                    '¡Advertencia!',
                    'Ingrese un email válido.',
                    'warning'
                ).then((result) => {
                    setLoading(false);
                })
            }
        }else{
            Swal.fire({
                title:'¡Advertencia!',
                text:'Llene todos los campos.',
                icon:'warning',
                allowOutsideClick: false
            }).then((result) => {
                setLoading(false);
            })
        }
    }

    return(
        <div id="contact">
            <Row className="m-5">
                <Col xs={6} className="d-none d-md-block">
                    <Image src={contact} style={{width: '100%', height: '100%'}} alt="contact"/>
                </Col>
                <Col xs={12} md={6}>
                    <h1 className="text-center my-2" style={{color:"#8A4AFF"}}>
                        <strong>
                            Contacto
                        </strong>
                    </h1>
                    <form onSubmit={handleSubmit}>
                        <h5 className="px-2" style={{color:"#8A4AFF"}}>
                            <strong>
                                Nombre
                            </strong>
                        </h5>
                        <input maxLength={50}
                            className="form-control border-left-0 border-right-0 border-top-0 mb-3" type="text"
                            placeholder="Ingrese su nombre" onChange={handleName} value={name}/>
                        <h5 className="px-2" style={{color:"#8A4AFF"}}>
                            <strong>
                                Correo electrónico
                            </strong>
                        </h5>
                        <input maxLength={30}
                            className="form-control border-left-0 border-right-0 border-top-0 mb-3" type="text"
                            placeholder="Ingrese su email" onChange={handleEmail} value={email}/>
                        <h5 className="px-2" style={{color:"#8A4AFF"}}>
                            <strong>
                                Asunto
                            </strong>
                        </h5>
                        <input maxLength={70}
                            className="form-control border-left-0 border-right-0 border-top-0 mb-3" type="text"
                            placeholder="Ingrese un asunto" onChange={handleSubject} value={subject}/>
                        <h5 className="px-2" style={{color:"#8A4AFF"}}>
                            <strong>
                                Mensaje
                            </strong>
                        </h5>
                        <textarea maxLength={200}
                            className="form-control border-left-0 border-right-0 border-top-0 resize-none mb-3" rows="2"
                            placeholder="Ingrese su mensaje" onChange={handleMessage} value={message}/>
                        <div className="d-flex justify-content-center">
                            <button className="btn-lg btn_registry_here px-3 pb-2 w-50" type="submit">
                                <b className="btn_registry_here_text my-3 mx-0 mx-md-3">
                                    {loading ?
                                        <div className="spinner-border text-light" role="status"/>
                                        :
                                        "Enviar"
                                    }
                                </b>
                            </button>
                        </div>
                    </form>
                </Col>
            </Row>
        </div>
    );
}
